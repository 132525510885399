import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Section, Title, Text } from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import Head from "../components/Head/Head"

const NotFound = () => {
  return (
    <>
      <PageWrapper>
        <Head title="404: Not Found" />
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Title variant="secSm" className="my-4">
                  404: Not Found
                </Title>
                <Text variant="p">
                  You just hit a route that doesn't exist... the sadness.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};

export default NotFound;
